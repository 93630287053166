/* Filtres sur les déclinaisons page produit */
.box-filtres-c {
  position: relative;
  background-color: $color7;
  padding: 1.5rem 1.5rem 1rem;
  margin-top: 1rem;
  z-index: 10;

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .h2 {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    .nb-produits {
      font-size: 1.4rem;
    }
  }

  .h3 {
    color: $color16;
    font-weight: 400;
    font-size: 1.6rem;
    text-transform: none;
  }

  .box-filtres {
    display: flex;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;

    .kcustom-select {
      flex-direction: column;
      background-color: #fff;
      cursor: default;
      width: 15.1rem;
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: 1rem;

      &::after {
        top: 1.5rem;
        color: var(--color1);
        pointer-events: none;
        transition: transform .3s;
      }

      .fake-select {
        color: $color16;
        background-color: #fff;
        border: .1rem solid #bbb;
        padding: 0;
        cursor: pointer;

        .h4 {
          font-size: 1.5rem;
          padding: 1rem 1.5rem 1rem 0.2rem;
          margin: 0;
        }
      }

      .list-fake-options {
        max-height: 0;
        overflow: hidden;

        .fake-option {
          display: block;
          cursor: pointer;
          padding-left: .5rem;
          padding-top: .5rem;
          padding-bottom: .5rem;
          margin-bottom: 0;

          &:hover {
            background-color: #dedddd;
          }

          input {
            margin-right: .1rem;
          }

          .fake-option-libelle {
            margin-top: .3rem;
            margin-bottom: .3rem;
          }
        }
      }
    }

    .kcustom-select.open {
      .list-fake-options {
        position: absolute;
        top: 3.7rem;
        max-height: 31.2rem;
        overflow: auto;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
        width: calc(100% - .2rem);
        margin-left: .1rem;
        background-color: #fff;
        z-index: 9;
      }

      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .button {
    color: var(--color1);
    background-color: #fff;
    cursor: pointer;
    height: 3.4rem;

    &:focus,
    &:hover {
      opacity: .8;
      color: var(--color1);
      background-color: #fff;
    }
  }

  .list-filtres-actifs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -.4rem;
    margin-right: -.4rem;

    .filtre-actif {
      display: inline-block;
      padding: 0.5rem 1rem;
      margin-left: 0.4rem;
      margin-right: 0.4rem;
      margin-bottom: .4rem;
      background-color: #fff;
      cursor: pointer;

      .icon-close {
        font-size: 1.2rem;
        margin-right: .8rem;
        color: var(--color1);
      }

      &:hover .icon-close {
        color: $color16;
      }
    }
  }
}

.sort_me_table .ligne_declinaison {
  display: none;

  &.ligne_choisie {
    display: table-row;
  }
}

.detail_prod_table.truncate_table {
  position: relative;
  max-height: 31rem;
  overflow: hidden;

  .sort_me_table_overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7007003484987745) 25%, rgba(255, 255, 255, 0) 50%);
    height: 12rem;
    z-index: 9;
  }

  .no-product {
    text-align: center;
    margin-bottom: 2rem;
  }
}

body .plus-declinaisons-c {
  display: block;
  position: relative;
  z-index: 10;
  margin-top: -2rem;
  margin-bottom: 2rem;

  .button {
    cursor: pointer;

    &:hover {
      color: var(--color1);
      background-color: #fff;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .box-filtres-c .box-filtres .kcustom-select {
    width: 14.5rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .box-filtres-c .box-filtres .kcustom-select {
    width: 14.1rem;
  }
}

@media (max-width: $breakpoint-md) {
  .box-filtres-c .box-filtres .kcustom-select {
    width: 15.7rem;
  }
  .detail_prod_table.truncate_table {
    &.overflow-hidden {
      overflow: hidden;
    }

    &.overflow-auto {
      overflow: auto;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .box-filtres-c {
    header {
      flex-wrap: wrap;

      .h2 {
        margin-right: 2rem;
      }

      .button {
        margin-top: 1rem;
      }
    }

    .box-filtres {
      justify-content: center;

      .kcustom-select {
        width: 15rem;
      }
    }

    .list-filtres-actifs {
      justify-content: center;
    }
  }
}